import { isSomeEnum } from '@canalplus/mycanal-commons';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { isTOfferLocation } from '@canalplus/sdk-core';
import { createSelector } from '@reduxjs/toolkit';
import { getDeviceIdFromPlatform, getDeviceTypeFromPlatform } from '../../helpers/application/application-helper';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { ConfigFetchInitLiveTV } from '../../services/fetchLiveTVChannelsService/fetchLiveTVAccessibleChannelsService';
import { PlayerState } from '../../store/slices/player';
import { IState } from '../../store/types/State-type';
import { offerLocationSelector, offerZoneSelector, platformSelector } from './application-selectors';

const playerSelector = (state: IState): PlayerState => state?.player;

export const playerTypeSelector = createSelector(playerSelector, (player) => player?.playerType);

export const playerInstanceSelector = createSelector(playerSelector, (player) => player?.playerInstance);

export const playerSettingsSelector = createSelector(playerSelector, (player) => player?.settings);

export const areSettingsSetSelector = createSelector(playerSettingsSelector, (settings) => settings !== null);

export const isTrailerSelector = createSelector(playerSettingsSelector, (settings) => {
  return !!settings?.refererTrackingId?.includes('trailers');
});

export const isPlayerOpenSelector = createSelector(playerSelector, (player) => player?.open);

export const playerLastCurrentTimeSelector = createSelector(playerSelector, (player) => player?.lastCurrentTime);

export const playerLastWatchedEpisodeStreamIdSelector = createSelector(
  playerSelector,
  (player) => player?.lastWatchedEpisodeStreamId
);

export const playerFatalErrorSelector = createSelector(playerSelector, (player) => player?.fatalError);

export const isFullScreenSelector = createSelector(playerSelector, (player) => player?.isFullScreen || false);

export const trackingContextSelector = createSelector(playerSelector, (player) => player?.trackingContext || {});

export const infoTrackingSelector = createSelector(playerSelector, (player) => player?.infoTracking || {});

/** @public */
export const playerDataSelector = createSelector(playerSelector, (player) => player?.data || {});

export const playerConfigSelector: (state: IState) => ConfigFetchInitLiveTV = createSelector(
  offerZoneSelector,
  offerLocationSelector,
  platformSelector,
  (offerZone, offerLocation, device) => {
    const publicConfig = getPublicConfig();

    if (!isSomeEnum(BusinessTypes.OFFER_ZONE)(offerZone)) {
      // Unfortunately there's no better way to do this until we sync offer zones with the player.
      // The alternative is to fail silently by setting a default offerZone, by this will cause
      // undesired behavior.
      // Can be caught via a react ErrorBoundary if necessary.
      throw new Error(`OfferZone "${offerZone}" stored in state is not compatible with player offer zones`);
    }

    return {
      context: publicConfig.PLAYER.ONE_PLAYER.CONTEXT,
      offerZone,
      deviceType: getDeviceTypeFromPlatform(device) as BusinessTypes.DEVICE_TYPES,
      offerLocation: isTOfferLocation(offerLocation) ? offerLocation : null,
      deviceId: getDeviceIdFromPlatform(device),
      env: publicConfig.PLAYER.ONE_PLAYER.ONE_PLAYER_ENV,
      configBaseUrl: publicConfig.PLAYER.ONE_PLAYER.CONFIG_BASE_URL,
    };
  }
);
