import type { IUserInformation } from '@canalplus/mycanal-sdk';
import { isOfferZone } from '@canalplus/sdk-core';
import type { PassUserDataEnriched } from '@canalplus/sdk-pass';
import type { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { formatUserData, sortProfiles } from '../../helpers/user/user-helper';
import type { StringNumberBoolean } from '../../typings/commons';
import { closeImmersive } from './immersive';
import type {
  AccessibleChannel,
  UserExternalServices,
  UserNotification,
  UserSaleAddress,
  UserSettingsState,
  UserState,
} from './user-type';

export const initialState = {
  authenticated: false,
  areAccessibleChannelsLoaded: false,
  isFetching: false,
  sale: {},
  settings: {},
  targetedAds: '0',
} satisfies Partial<UserState> as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    /** To dispatch to display the profile menu */
    displayProfileMenu(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        displayProfileMenu: action.payload,
      };
    },

    getPassToken(state, action: PayloadAction<string>) {
      return {
        ...state,
        passToken: action.payload,
        passTokenCreationDate: new Date().getTime(),
        waitForPassFinish: true,
      };
    },

    /** To dispatch when the user data have been successfully fetched */
    receiveUserData(state, action: PayloadAction<IUserInformation>) {
      const userData = formatUserData(action.payload);

      if (!isOfferZone(userData.offerZone)) {
        throw new Error('[receiveUserData Redux action]: OfferZone is not valid');
      }

      return {
        ...state,
        authenticated: userData.authenticated,
        epgid: userData.epgid,
        epgidOTT: userData.epgidOTT,
        isFetching: false,
        macroEligibility: userData.macroEligibility,
        microEligibility: userData.microEligibility,
        offerZone: userData.offerZone,
        reco: userData.reco,
        analyticsId: userData.analyticsId,
        anonymousId: userData.dmpId,
        settings: {
          ...state.settings,
          hasUserDataCollected: userData.collectUserData,
          hasAnalyticsCollected: userData.analytics,
          hasTrackingPubCollected: userData.trackingPub,
          hasAnonymousTracking: userData.anonymousTracking,
        },
      };
    },

    /** To dispatch when the user information have been successfully retrieve */
    receiveUserInfos(state, action: PayloadAction<(IUserInformation | PassUserDataEnriched) & { isPartner: boolean }>) {
      return {
        ...state,
        accountId: action.payload.accountId,
        analyticsId: action.payload.id_analytics,
        anonymousId: action.payload.dmpId,
        cgaNumber: action.payload.cgaNumber,
        idpToken: action.payload.idpToken,
        isPartner: action.payload.isPartner,
        passId: action.payload.passId,
        subscriberId: action.payload.subscriberId,
        profile: {
          ...state.profile,
          email: action.payload.email,
          profilesUpdateDate: action.payload.profilesUpdateDate,
        },
        settings: {
          ...state.settings,
          hasUserDataCollected: action.payload.collectUserData,
          hasAnalyticsCollected: action.payload.analytics,
          hasTrackingPubCollected: action.payload.trackingPub,
          hasAnonymousTracking: action.payload.anonymousTracking,
        },
      };
    },

    /** To dispatch to store ab-testing percentage */
    setAbTestingPercentage(state, action: PayloadAction<number>) {
      return {
        ...state,
        abTestingPercentage: action.payload,
      };
    },

    /** To dispatch when the hodor ab-testing population has been defined */
    setAbTestingPopulation(state, action: PayloadAction<number>) {
      return {
        ...state,
        abTestingPopulation: action.payload,
      };
    },

    /** To dispatch when the user has set accessible channels */
    setAccessibleChannels(state, action: PayloadAction<AccessibleChannel[]>) {
      return {
        ...state,
        accessibleChannels: action.payload,
        areAccessibleChannelsLoaded: true,
      };
    },

    /** To dispatch when the user has set an ad token */
    setAdToken(state, action: PayloadAction<string>) {
      return {
        ...state,
        adToken: action.payload,
      };
    },

    setExternalServicesInfo(state, action: PayloadAction<UserExternalServices[]>) {
      return {
        ...state,
        externalServices: action.payload,
      };
    },

    /** To dispatch when the trailer is temporarily unmuted */
    setIsTrailerTemporarilyUnmuted(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        settings: {
          ...state.settings,
          isTrailerTemporarilyUnmuted: action.payload,
        },
      };
    },

    setIsShortVideoListTemporarilyUnmuted(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        settings: {
          ...state.settings,
          isShortVideoListTemporarilyUnmuted: action.payload,
        },
      };
    },

    /** To dispatch when the user has set the address proposals */
    setProposals(state, action: PayloadAction<UserSaleAddress['proposals']>) {
      return {
        ...state,
        sale: {
          ...state.sale,
          userAddress: { ...state.sale.userAddress, proposals: action.payload, timeoutId: undefined },
        },
      };
    },

    /** To dispatch when user has consented to GDPR */
    setTargetedAds(state, action: PayloadAction<StringNumberBoolean>) {
      return {
        ...state,
        targetedAds: action.payload,
      };
    },

    /** To dispatch when you have retrieved the user notification */
    setUserNotification(state, action: PayloadAction<UserNotification>) {
      return {
        ...state,
        notification: action.payload,
      };
    },

    setReco(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        reco: action.payload,
      };
    },

    showMoodExplanation(state, action: PayloadAction<boolean | undefined>) {
      return {
        ...state,
        settings: {
          ...state.settings,
          showMoodExplanation: action.payload || false,
        },
      };
    },

    /** Dispatched when user settings need to be changed */
    userChangeSettings(state, action: PayloadAction<UserSettingsState>) {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    },

    /** To dispatch when user data fetching fails */
    userDataError(state) {
      return {
        ...state,
        isFetching: false,
      };
    },

    /** To dispatch to store the current user profile */
    userListProfile(state, action: PayloadAction<ApiV2Profile>) {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    },

    /** To dispatch to store a user's profile list */
    userListProfiles(state, action: PayloadAction<ApiV2Profile[]>) {
      return {
        ...state,
        profiles: sortProfiles(action.payload),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(closeImmersive, (state) => {
      return {
        ...state,
        sale: {},
      };
    });
  },
});

export const {
  displayProfileMenu,
  getPassToken,
  receiveUserData,
  receiveUserInfos,
  setAbTestingPercentage,
  setAbTestingPopulation,
  setAccessibleChannels,
  setAdToken,
  setExternalServicesInfo,
  setIsTrailerTemporarilyUnmuted,
  setIsShortVideoListTemporarilyUnmuted,
  setProposals,
  setTargetedAds,
  setUserNotification,
  setReco,
  showMoodExplanation,
  userChangeSettings,
  userDataError,
  userListProfile,
  userListProfiles,
} = userSlice.actions;

export const userReducer = userSlice.reducer;

export type UserActions = ReturnType<(typeof userSlice.actions)[keyof typeof userSlice.actions]>;
