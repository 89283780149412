import { LOCATION_CHANGE, type LocationChangeAction } from 'connected-react-router';
import type { AllActionsTypes } from '../reducers/actions/All-actions-types';
import { getFeatureTogglePerso } from '../slices/application-selectors';
import { isImmersiveSelector } from '../slices/immersive-selectors';
import { refetchPerso } from '../slices/page';
import { destroyPlayer, killPlayer } from '../slices/player';
import { isPlayerOpenSelector, playerInstanceSelector } from '../slices/player-selectors';
import { hasUserDataCollectedSelector } from '../slices/user-selectors';

/**
 * Handles playerInstance side effects: to destroy or kill player
 */
export const playerInstanceMiddleware: Redux.Middleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const state = getState();

    switch ((action as AllActionsTypes).type) {
      /**
       * When destroy player is dispatched, we'll look for playerInstance in the store and call its destroy() method.
       * Player instance itself is kept in store to avoid requesting licenses and other stuff again
       */
      case destroyPlayer.type: {
        const player = playerInstanceSelector(state);
        if (Object.keys(player).length) {
          player.destroy();
        }
        break;
      }

      case killPlayer.type: {
        const isImmersive = isImmersiveSelector(state);
        const isPersoActive = getFeatureTogglePerso(state);
        const hasUserDataCollected = hasUserDataCollectedSelector(state);

        if (isPersoActive && !isImmersive && hasUserDataCollected) {
          dispatch(refetchPerso(true));
        }

        break;
      }

      /** If player is active and user navigates back - kill player */
      case LOCATION_CHANGE: {
        if ((action as LocationChangeAction).payload.action === 'POP' && isPlayerOpenSelector(state)) {
          dispatch(killPlayer());
        }
        break;
      }

      default:
        break;
    }

    return next(action);
  };
