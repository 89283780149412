import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ImmersiveMeta } from '../../components/Immersive/types';
import type { AlternateRecord } from './alternateLinks';
import { errorTemplate } from './error';

export type Immersive = {
  mainOnClick?: Routing.IMyCanalOnClick;
  action?: string;
  active?: boolean;
  meta?: ImmersiveMeta;
  persoUpdated?: boolean;
  clickedElementId?: string;
  tracking?: Tracking | ApiV2PageTracking;
  uniqueId?: string;
  URLPage?: string;
  alternateLinks?: AlternateRecord;
};

export const initialState = {
  mainOnClick: {
    displayTemplate: '',
    displayName: '',
    path: '',
  },
  active: false,
  action: '',
  persoUpdated: false,
  meta: {
    title: '',
  },
} satisfies Immersive as Immersive;

const immersiveSlice = createSlice({
  name: 'immersive',
  initialState,
  reducers: {
    /** Open the immersive */
    openImmersive(state, action: PayloadAction<Immersive>) {
      return {
        ...state,
        ...action.payload,
        meta: {
          ...action.payload.meta,
          snippets: JSON.stringify(action.payload.meta?.snippets),
        },
        active: true,
        uniqueId: uuidv4(),
      };
    },

    /** Close the immersive */
    closeImmersive() {
      return {
        active: false,
      };
    },

    /** Sets if some perso stuff has been modified from an immersive */
    setPersoUpdated(state, action: PayloadAction<Immersive['persoUpdated']>) {
      return {
        ...state,
        persoUpdated: action.payload,
      };
    },

    setImmersiveTracking(state, action: PayloadAction<Immersive['tracking']>) {
      return {
        ...state,
        tracking: action.payload,
      };
    },

    /** Updates the meta info for the immersive */
    setImmersiveMeta(state, action: PayloadAction<NonNullable<Immersive['meta']>>) {
      return {
        ...state,
        meta: action.payload,
      };
    },

    /** Updates the alternate links for the immersive */
    setImmersiveAlternateLinks(state, action: PayloadAction<Immersive['alternateLinks']>) {
      return {
        ...state,
        alternateLinks: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(errorTemplate, (state, action) => {
      const { displayTemplate, displayName, path, immersive } = action.payload;

      // Do not update immersive state if immersive mode is not active
      if (!immersive) {
        return { ...state };
      }

      return {
        ...state,
        mainOnClick: {
          displayTemplate,
          displayName,
          path,
        },
      };
    });
  },
});

export const {
  openImmersive,
  closeImmersive,
  setPersoUpdated,
  setImmersiveTracking,
  setImmersiveMeta,
  setImmersiveAlternateLinks,
} = immersiveSlice.actions;

export const immersiveReducer = immersiveSlice.reducer;

export type ImmersiveActions = ReturnType<(typeof immersiveSlice.actions)[keyof typeof immersiveSlice.actions]>;
