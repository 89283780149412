import {
  OneCoreContext,
  OneCoreProfile,
  OneCoreSystem,
  getContext,
  getProfile,
  getSystem,
} from '@canalplus/ifc-onecore';
import { IUserInformation } from '@canalplus/mycanal-sdk';
import { OfferLocation, OfferZone, OneCorePlatform } from '@canalplus/sdk-core';
import { log } from '../../components/VisualDebugger/html-logger';
import { ResizeMode } from '../../constants/resize';
import { BufferQueries, Queries } from '../../constants/url';
import { getResizeModeAuto } from '../resize/ResizeHelper';
import { isR7Available } from '../window/window-helper';
import { getBuild } from './getBuild';

/**
 * Initial query params when start app
 */
let initialQueryParams = '';

/**
 * To save the initial query params when start app
 * @param queryStr string
 * @returns void
 */
export const setInitialQueryParams = (queryStr: string): void => {
  initialQueryParams = queryStr;
};

/**
 * To get the initial query params when we started the app
 * @returns string
 */
const getInitialQueryParams = (): string => {
  return initialQueryParams;
};

function parseQs(str: string): Record<string, string> {
  return str
    .replace(/^[?&]/, '')
    .split('&')
    .reduce((query, param) => {
      const [key, value] = param.split('=');

      if (key && value) {
        return { ...query, [key]: decodeURIComponent(value) };
      }

      return query;
    }, {});
}

function serializeQs(query: Record<string, string | number | boolean>): string {
  return Object.entries(query)
    .reduce<string[]>((qs, [key, value]) => [...qs, `${key}=${value}`], [])
    .join('&');
}

export type OneDiscoveryBufferUrlParameters = {
  profile: OneCoreProfile;
  system: OneCoreSystem;
  context: OneCoreContext;
  query: Record<string, string>;
};

/**
 * Filter object queries to have only supported queries values for buffer entry page.
 * See enum BufferQueries to know supported queries values
 * @param queryParams Record<string, string>
 * @returns Record<string, string> queryParams filtered
 */
export function filterQueryParamsBuffer(queryParams: Record<string, string>): Record<string, string> {
  return Object.entries(BufferQueries)
    .filter((entry: [string, BufferQueries]) => queryParams[entry[1]])
    .reduce(
      (previousValue, currentValue) => ({ ...previousValue, [currentValue[1]]: queryParams[currentValue[1]] }),
      {}
    );
}

/**
 * Parse the one-core deeplink path. This prevents unwanted behavior when one-core sends things such as "#consent/"
 * @param oneCorePath Raw path returned by one-core
 * @returns the pathname if successfully parsed, undefined otherwise
 */
export function parseOneCoreDeeplinkPath(oneCorePath?: string): string | undefined {
  if (!oneCorePath) {
    return undefined;
  }

  try {
    const parsedUrl = new URL(`${window.location.origin}${oneCorePath}`);

    return parsedUrl.pathname !== '/' ? parsedUrl.pathname : undefined;
  } catch {
    return undefined;
  }
}

/** Compute the OneDiscovery buffer url. */
export function getOneDiscoveryBufferUrl({ profile, system, context, query }: OneDiscoveryBufferUrlParameters): string {
  const offerLocation = (profile.offerLocation || OfferLocation.fr).toLowerCase();
  const offerZone = (profile.offerZone || OfferZone.cpfra).toLowerCase();

  const redirectQueries: Record<string, string> = {
    [Queries.Display]: 'tv',
    [Queries.Build]: getBuild(navigator.userAgent),
    [Queries.OfferLocation]: offerLocation,
    [Queries.OfferZone]: offerZone,
    [Queries.Buffer]: 'false',
  };

  const filteredQueryParamsBuffer = filterQueryParamsBuffer(query);

  // Copy params from query (except path)
  Object.keys(filteredQueryParamsBuffer).forEach((key: string) => {
    if (key !== Queries.Path) {
      redirectQueries[key] = filteredQueryParamsBuffer[key];
    }
  });

  if (profile.passId && (profile.accountId || profile.subscriberId)) {
    redirectQueries[Queries.Token] = encodeURIComponent(profile.passId);
  }

  if (system.target) {
    redirectQueries[Queries.Platform] =
      Object.entries(OneCorePlatform).find(([, value]) => value === system.target)?.[0] || OneCorePlatform.Web;
  }

  if (profile.askForConsent) {
    redirectQueries[Queries.DisplayGDPR] = 'true';
  }

  // Handle initial history
  let homePath = ([OfferLocation.fr, OfferLocation.it] as string[]).includes(offerLocation)
    ? '/'
    : `/${offerLocation}/`;

  // Temporary dev for german-speaking switzerland (waiting for the multi-language)
  if (offerZone === OfferZone.cpchd) {
    homePath = `/${offerLocation}/de/`;
  }

  const initialHistoryEntries = [homePath];

  const { deeplink: { path: rawDeepLinkPath } = {} } = context;
  // We take in priority the path given via qs to buffer page, otherwise we try to extract a deeplink
  // path given by one-core
  const deepLinkPath = filteredQueryParamsBuffer.path || parseOneCoreDeeplinkPath(rawDeepLinkPath);

  if (deepLinkPath) {
    initialHistoryEntries.push(deepLinkPath);
  }

  redirectQueries[Queries.InitialHistoryEntries] = encodeURIComponent(JSON.stringify(initialHistoryEntries));
  let redirectPath = deepLinkPath || filteredQueryParamsBuffer.path || homePath;

  if ($_BUILD_RENDERMODE_CSR) {
    // The full CSR instance can be hosted within a subfolder (as for Orange).
    // It is therefore required to systematically point to a root url '/' and to manage our Hodor page calls through a "path" query.
    redirectPath = window.location.pathname;
  }

  return `${redirectPath}?${serializeQs(redirectQueries)}`;
}

/**
 * Use R7extlib to compute the OneDiscovery buffer url and redirect to it.
 * @param queryParamsStr string queryParams from buffer page
 * @returns void
 */
export async function redirectToAppWithQueriesAndOneCoreData(
  queryParamsStr: string,
  replace: boolean = false,
  done?: (options: { profile: OneCoreProfile; system: OneCoreSystem; context: OneCoreContext }) => void
): Promise<void> {
  const queryParamsBuffer = parseQs(queryParamsStr);

  // if no resize query param, apply detection resize auto
  if (!queryParamsBuffer[BufferQueries.Resize] || queryParamsBuffer[BufferQueries.Resize] === ResizeMode.RESIZE_AUTO) {
    queryParamsBuffer[BufferQueries.Resize] = getResizeModeAuto();
  }

  try {
    const [profile, system, context] = await Promise.all([getProfile(), getSystem(), getContext()]);

    // We need to set OneDiscovery version for OneCore (visible in hidden menu)
    window.R7('setExternalFrameVersion', {
      version: `${$_BUILD_APP_VERSION} (${$_BUILD_APP_GIT_REF})`,
      service: 'onediscovery_version',
    });

    const redirectUrl = getOneDiscoveryBufferUrl({ profile, system, context, query: queryParamsBuffer });

    if (replace) {
      window.history.replaceState(window.history.state, '', redirectUrl);
    } else {
      console.log(`[OneDiscovery] You are redirected to this url: ${redirectUrl}`);
      window.location.replace(redirectUrl);
    }

    done?.({ profile, system, context });
  } catch (onecoreError) {
    console.error(onecoreError);
  }
}

export function formatOneCoreProfileData(data: OneCoreProfile): IUserInformation {
  // OneCore getProfile response is not formatted exactly like the createToken Pass response.
  // OneCore must send us the same dataset as that of the Pass createToken.
  return {
    ...data,
    epgid: Object.entries(data.epgid || {})
      .filter(([_epg, enabled]) => !!enabled)
      .map(([epg]) => epg)
      .join(','),
    epgid_cat5: data.macroEligibility.join(','),
    macroEligibility: data.macroEligibility.join(','),
    microEligibility: JSON.stringify(data.microEligibility).replace(/["\s{}]/g, ''),
  } as any;
}

/**
 * Reload the app with the initial query params
 */
export const reloadAppWithInitialQueryParams = async (): Promise<void> => {
  return redirectToAppWithQueriesAndOneCoreData(getInitialQueryParams() || window.location.search);
};

/**
 * Update rights with R7 lib in tvDevice
 * @returns Promise<IUserInformation> return IUserInformation if success
 */
export const refreshProfileOneCore = (): Promise<IUserInformation> => {
  return new Promise((resolve, reject) => {
    if (!isR7Available()) {
      reject(new Error('window.R7 is not defined'));
      return;
    }

    try {
      window.R7(
        'refreshProfile',
        {
          forceRefresh: true,
        },
        (err, data) => {
          if (err) {
            return reject(err);
          }

          if (!data) {
            return reject(new Error(`window.R7('refreshProfile') failed, data undefined`));
          }

          resolve(formatOneCoreProfileData(data as unknown as OneCoreProfile));
        }
      );
    } catch (e) {
      log({ level: 'error', tags: ['refreshProfile'], message: `ERROR refreshProfile: ${e}` });
      reject(new Error(`window.R7('refreshProfile') failed: ${e}`));
    }
  });
};

/**
 * Update rights with R7 lib in tvDevice and reload the app with new token
 * @returns Promise<void>
 */
export const refreshProfileOneCoreAndReload = async (): Promise<void> => {
  try {
    await refreshProfileOneCore();
    await reloadAppWithInitialQueryParams();
  } catch (e) {
    log({ level: 'error', tags: ['refreshProfileAndReload'], message: `ERROR refreshProfileAndReload: ${e}` });
  }
};
