import { combineReducers, configureStore as configureStoreToolkit } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import type { Request } from 'express';
import { DeepPartial } from 'ts-essentials';
import { Queries } from '../constants/url';
import createHistory from '../createHistory';
import { AppHistory, LocationState } from '../typings/routing';
import { middleware } from './middleware';
import reducers from './reducers';
import { adultReducer } from './slices/adult';
import { alternateLinksReducer } from './slices/alternateLinks';
import { applicationReducer } from './slices/application';
import { contextFeatureReducer } from './slices/contextFeature';
import { displayModeReducer } from './slices/displayMode';
import { errorReducer } from './slices/error';
import { immersiveReducer } from './slices/immersive';
import { metaReducer } from './slices/meta';
import { modalReducer } from './slices/modal';
import { pageReducer } from './slices/page';
import { playerReducer } from './slices/player';
import { profilesModalReducer } from './slices/profilesModal';
import { purchaseCodeReducer } from './slices/purchaseCode';
import { searchReducer } from './slices/search';
import { stickyBarReducer } from './slices/stickyBar';
import { uiReducer } from './slices/ui';
import { userReducer } from './slices/user';
import sanitizeState from './stateSanitizers';
import { IState } from './types/State-type';

const isServer = !(typeof window !== 'undefined' && window.document && window.document?.createElement);

const shouldUseDevtools = (appHistory: AppHistory) => {
  const { search } = appHistory.location || {};
  const hasDevtoolsQuery = new URLSearchParams(search).get(Queries.Devtools) === 'true';

  return !isServer && (process.env.NODE_ENV === 'development' || hasDevtoolsQuery);
};

export type GetIsomorphicStoreParameters = {
  request?: Request;
  initialHistoryEntries?: string[];
  isTvDevice?: boolean;

  /** Usually used in jest environment */
  preloadedState?: DeepPartial<IState>;
};

export type IsomorphicStore = {
  store: Redux.CustomStore;
  history: AppHistory;
};

export function getIsomorphicStore({
  request,
  initialHistoryEntries = [],
  isTvDevice,
  preloadedState,
}: GetIsomorphicStoreParameters = {}): IsomorphicStore {
  const initialEntries = isServer && request ? [request.url] : initialHistoryEntries;
  const history = createHistory(initialEntries, isTvDevice);

  const middlewares = [routerMiddleware<LocationState | undefined>(history), ...middleware] as Redux.Middleware[];

  const store = configureStoreToolkit({
    reducer: combineReducers({
      ...reducers,
      adult: adultReducer,
      alternateLinks: alternateLinksReducer,
      application: applicationReducer,
      contextFeature: contextFeatureReducer,
      displayMode: displayModeReducer,
      error: errorReducer,
      immersive: immersiveReducer,
      meta: metaReducer,
      modal: modalReducer,
      page: pageReducer,
      player: playerReducer,
      profilesModal: profilesModalReducer,
      purchaseCode: purchaseCodeReducer,
      router: connectRouter(history),
      search: searchReducer,
      stickyBar: stickyBarReducer,
      ui: uiReducer,
      user: userReducer,
    }),
    devTools: shouldUseDevtools(history)
      ? {
          name: `myCANAL - ${process.env.NODE_ENV || 'development'}`,
          stateSanitizer: sanitizeState,
        }
      : false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(middlewares),
    preloadedState: !isServer ? window.__data || preloadedState : {},
  });

  if (!isServer) {
    history.replace({ ...history.location, state: { fromSSR: $_BUILD_RENDERMODE_SSR } });
    delete window.__data;
  }

  return { store, history };
}
