import { OfferLocation, Platform } from '@canalplus/sdk-core';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';

export enum ActionExternalServicesTypeKeys {
  LAUNCH_EXTERNAL_SERVICE = 'LAUNCH_EXTERNAL_SERVICE',
  HANDLE_LAUNCH_EXTERNAL_SERVICE = 'HANDLE_LAUNCH_EXTERNAL_SERVICE',
}

export interface ILaunchExternalServiceAction {
  readonly type: ActionExternalServicesTypeKeys.LAUNCH_EXTERNAL_SERVICE;
  readonly payload: { URLMedias: string; isDownload: boolean };
}

export const launchExternalService = (
  URLMedias: string,
  isDownload: boolean = false
): ILaunchExternalServiceAction => ({
  payload: { URLMedias, isDownload },
  type: ActionExternalServicesTypeKeys.LAUNCH_EXTERNAL_SERVICE,
});

type LaunchExternalServiceActionPayload = {
  platform: Platform;
  target: string;
  URLPage?: string;
  contentID?: string;
  URLWebsite?: string;
  trackingContext?: ApiV2Context;
  serviceID?: string;
  offerLocation?: OfferLocation;
};
export interface IHandleLaunchExternalServiceAction {
  readonly type: ActionExternalServicesTypeKeys.HANDLE_LAUNCH_EXTERNAL_SERVICE;
  readonly payload: LaunchExternalServiceActionPayload;
}

export const handleLaunchExternalService = ({
  URLPage,
  contentID,
  URLWebsite,
  trackingContext,
  serviceID,
  target,
  platform,
  offerLocation,
}: LaunchExternalServiceActionPayload): IHandleLaunchExternalServiceAction => ({
  payload: { platform, URLPage, contentID, URLWebsite, trackingContext, serviceID, target, offerLocation },
  type: ActionExternalServicesTypeKeys.HANDLE_LAUNCH_EXTERNAL_SERVICE,
});

export type ExternalServiceActionTypes = ILaunchExternalServiceAction | IHandleLaunchExternalServiceAction;
