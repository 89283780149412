import { fillTemplate } from '@canalplus/mycanal-commons';
import { OfferLocation, PassPlatform, Platform } from '@canalplus/sdk-core';
import { requestPage } from '@canalplus/sdk-hodor';
import { ApiV2GetMediaUrlPage } from '@dce-front/hodor-types';
import { DataLayerTracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { DeeplinkDetailInformation } from '@dce-front/hodor-types/api/v2/me/get_media_url/definitions';
import { ApiV2PageTrackingDataLayer } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { R7NavigateDataSkey } from 'r7extlib';
import { Middleware } from 'redux';
import { apiCallWithTokenpassRenewal } from '../../api/PersoApi/PersoApi';
import type { ApiCallOptionParameters } from '../../api/types';
import { DEEPLINK, PLAY_DEEPLINK, START_DOWNLOAD_DEEPLINK } from '../../constants/tracking';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import {
  type SendDeeplinkTrackingEventOptions,
  sendDeeplinkTrackingEvent,
} from '../../helpers/tracking/tracking-helper';
import { fillUrlWithPassId, getUrlWithTokenCMS, isUrlContainsPassId } from '../../helpers/url/url-helper';
import { isR7Available } from '../../helpers/window/window-helper';
import type { AllActionsTypes } from '../reducers/actions/All-actions-types';
import {
  ActionExternalServicesTypeKeys,
  type IHandleLaunchExternalServiceAction,
  type ILaunchExternalServiceAction,
} from '../reducers/actions/ExternalServices-actions';
import {
  getFeatureExternalServicesSelector,
  hodorSdkConfigSelector,
  offerLocationSelector,
  tokenCMSSelector,
} from '../slices/application-selectors';
import { displayTVModeSelector } from '../slices/displayMode-selectors';
import { pageURLSelector } from '../slices/page-selectors';
import { effectiveTrackingContextSelector, findTrackingSelector } from '../slices/tracking-selectors';
import { abTestingPopulationSelector, isKidsProfileSelector, segTypeSelector } from '../slices/user-selectors';
import type { IState } from '../types/State-type';

const { LAUNCH_EXTERNAL_SERVICE, HANDLE_LAUNCH_EXTERNAL_SERVICE } = ActionExternalServicesTypeKeys;

/**
 * Launch external service web from detail URLMedias
 * @param detail
 * @param dataTracking
 */
const launchDetailExternalServiceWeb = (
  detail: ApiV2GetMediaUrlPage['detail'] | undefined,
  dataTracking: SendDeeplinkTrackingEventOptions
) => {
  // Get external service url from URLMedias response
  const externalUrl = (detail?.informations as DeeplinkDetailInformation)?.deeplink?.data?.url;

  if (externalUrl) {
    sendDeeplinkTrackingEvent(dataTracking);
    window.open(externalUrl, '_blank');
  } else {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. No externalUrl found`
    );
  }
};

/**
 * Launch external service web
 * @param target
 * @param platform
 * @param offerLocation
 * @param URLWebsite
 * @param serviceID
 */
const launchExternalServiceWeb = ({
  target,
  platform,
  offerLocation,
  URLWebsite,
  serviceID,
}: {
  target: string;
  platform: Platform;
  offerLocation: OfferLocation;
  serviceID?: string;
  URLWebsite?: string;
}) => {
  if (serviceID === 'LAUNCH_PRIVACY_MANAGER' && window.Didomi) {
    window.Didomi.notice.show();
  }

  if (URLWebsite) {
    let urlToOpen = URLWebsite;

    if (isUrlContainsPassId(URLWebsite)) {
      urlToOpen = fillUrlWithPassId(urlToOpen);
    }

    urlToOpen = fillTemplate(urlToOpen, [
      ['media', PassPlatform[platform]],
      ['appLocation', offerLocation],
    ]);

    window.open(urlToOpen, target);
  } else {
    Logger.error(`ExternalService::launchExternalService() - Error occurs during launchExternalServiceWeb.`);
  }
};

/**
 * Launch external service tv from detail URLMedias
 * @param detail
 * @param dataTracking
 */
const launchDetailExternalServiceTv = (
  detail: ApiV2GetMediaUrlPage['detail'] | undefined,
  dataTracking: SendDeeplinkTrackingEventOptions
) => {
  if (isR7Available()) {
    const deepLinkParams =
      detail && 'deeplink' in detail.informations ? detail.informations.deeplink?.data?.params : undefined;

    const serviceID = deepLinkParams
      ? deepLinkParams.find((item) => item.field?.toLowerCase() === 'serviceid')?.value
      : undefined;

    if (serviceID) {
      sendDeeplinkTrackingEvent(dataTracking);

      const contentID = deepLinkParams
        ? deepLinkParams.find((item) => item.field?.toLowerCase() === 'contentid')?.value
        : undefined;

      window.R7('navigate', {
        skey: serviceID,
        context: contentID ? { deeplink: { contentId: contentID } } : undefined,
      } as R7NavigateDataSkey);
    } else {
      Logger.error(
        `ExternalService::launchExternalService() - Error occurs during launchExternalService. No serviceID found.`
      );
    }
  } else {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. Api R7 is not available.`
    );
  }
};

/**
 * Launch external service tv
 * @param isTvDevice
 * @param contentID
 * @param serviceID
 */
const launchExternalServiceTV = ({
  isTvDevice,
  contentID,
  serviceID,
}: {
  isTvDevice: boolean;
  contentID?: string;
  serviceID?: string;
}) => {
  if (serviceID) {
    if (isTvDevice && isR7Available()) {
      window.R7('navigate', {
        skey: serviceID,
        // We should always use the contentID given in the onClick for deeplinks, the contentID on content is technical
        context: contentID ? { deeplink: { contentId: contentID } } : undefined,
      } as R7NavigateDataSkey);
    }
  }
};

export const externalServicesMiddleware: Middleware<object, IState, Redux.Dispatch> =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    const state = getState();

    switch ((action as AllActionsTypes).type) {
      case LAUNCH_EXTERNAL_SERVICE:
        try {
          const featExternalServices = getFeatureExternalServicesSelector(state);
          const tokenCMS = tokenCMSSelector(state);

          if (!featExternalServices || !tokenCMS) {
            break;
          }

          const { URLMedias, isDownload } = (action as ILaunchExternalServiceAction).payload;

          // Call URLMedias to get external service url (stream or download)
          const urlWithTokenCMS = getUrlWithTokenCMS(URLMedias, tokenCMS);

          const apiCall = (options: ApiCallOptionParameters) => {
            const newOptions = {
              headers: {
                ...options.headers,
                passtoken: options.headers.tokenPass,
              },
              timeout: getPublicConfig().api.paymentProvider.timeout.default,
            };
            // Delete unauthorized headers to avoid potential cors errors
            delete (newOptions as any).headers.passtoken;
            return fetch(urlWithTokenCMS, newOptions);
          };
          const response = await dispatch(apiCallWithTokenpassRenewal(apiCall as any));

          if (!response) {
            throw new Error('launchExternalService failed to fetch');
          }

          const json: ApiV2GetMediaUrlPage = await response.json();
          const { detail, tracking } = json || {};

          const dataLayer = tracking?.dataLayer || {};
          const context = effectiveTrackingContextSelector(state);
          const trackingWithContext = { ...dataLayer, ...context };
          const trackingName = isDownload ? START_DOWNLOAD_DEEPLINK : PLAY_DEEPLINK;
          const abTestingPopulation = abTestingPopulationSelector(state);
          const segType = segTypeSelector(state);
          const pageURL = pageURLSelector(state);
          const offerLocation = offerLocationSelector(state);
          const isKids = isKidsProfileSelector(state);
          const isTvDevice = displayTVModeSelector(state);

          const dataTracking: SendDeeplinkTrackingEventOptions = {
            tracking: trackingWithContext,
            name: trackingName,
            abTestingPopulation,
            segType,
            version: $_BUILD_APP_VERSION,
            pageURL,
            offerLocation,
            isKids,
            isTvDevice,
          };

          if (!isTvDevice) {
            launchDetailExternalServiceWeb(detail, dataTracking);
          } else {
            launchDetailExternalServiceTv(detail, dataTracking);
          }
        } catch (error) {
          Logger.error(`ExternalService::launchExternalService() - Error occurs during launchExternalService ${error}`);
        }
        break;

      case HANDLE_LAUNCH_EXTERNAL_SERVICE: {
        let tracking: DataLayerTracking | ApiV2PageTrackingDataLayer | undefined;
        const { URLPage, contentID, URLWebsite, trackingContext, serviceID, platform, target } = (
          action as IHandleLaunchExternalServiceAction
        ).payload;

        const isTvDevice = displayTVModeSelector(state);
        const context = effectiveTrackingContextSelector(state);
        const abTestingPopulation = abTestingPopulationSelector(state);
        const segType = segTypeSelector(state);
        const pageURL = pageURLSelector(state);
        const offerLocation = offerLocationSelector(state);
        const isKids = isKidsProfileSelector(state);
        const hodorSdkConfig = hodorSdkConfigSelector(state);

        try {
          if (URLPage && hodorSdkConfig) {
            // Call URLPage to get tracking object
            const response = (await requestPage(hodorSdkConfig, {
              url: URLPage,
            })) as any;

            tracking = response?.tracking?.dataLayer;
          } else {
            tracking = findTrackingSelector(state).dataLayer;
          }
          const trackingWithContext = { ...tracking, ...context, ...trackingContext };

          // Handle tracking for external site context
          if (trackingWithContext) {
            sendDeeplinkTrackingEvent({
              isTvDevice,
              tracking: trackingWithContext,
              name: DEEPLINK,
              abTestingPopulation,
              segType,
              version: $_BUILD_APP_VERSION,
              pageURL,
              offerLocation,
              isKids,
              ...(contentID && { contentID }),
              ...(URLWebsite && { urlWebsite: URLWebsite }),
            });
          }
        } catch (error) {
          Logger.error(`ExternalService::launchExternalService() - Error occurs during launchExternalService ${error}`);
        }

        if (isTvDevice) {
          launchExternalServiceTV({ isTvDevice, contentID, serviceID });
        } else {
          launchExternalServiceWeb({ target, platform, offerLocation, URLWebsite, serviceID });
        }

        break;
      }
      default:
        break;
    }

    return next(action);
  };
