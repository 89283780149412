import { LogConfig, Logger, LogLevel } from '../../types';
import { generateApiMetadata } from '../helpers/generateApiMetadata';
import { generateMetadata } from '../helpers/generateMetadata';
import { isRelevant } from '../helpers/isRelevant';

const getTimestamp = () => new Date().toISOString();

/** Temporary front logger until front log solution is chosen */
const makeFrontLogger = (minLevel: LogLevel) => ({
  error(message: string, metadata = {}) {
    if (!isRelevant('error', minLevel)) {
      return;
    }

    console.error(`${getTimestamp()} ERROR ${message} ${JSON.stringify(metadata)}`);
  },

  warn(message: string, metadata = {}) {
    if (!isRelevant('warn', minLevel)) {
      return;
    }

    console.warn(`${getTimestamp()} WARN ${message} ${JSON.stringify(metadata)}`);
  },

  info(message: string, metadata = {}) {
    if (!isRelevant('info', minLevel)) {
      return;
    }

    console.info(`${getTimestamp()} INFO ${message} ${JSON.stringify(metadata)}`);
  },

  verbose(message: string, metadata = {}) {
    if (!isRelevant('verbose', minLevel)) {
      return;
    }

    console.log(`${getTimestamp()} VERBOSE ${message} ${JSON.stringify(metadata)}`);
  },

  debug(message: string, metadata = {}) {
    if (!isRelevant('debug', minLevel)) {
      return;
    }

    console.log(`${getTimestamp()} DEBUG ${message} ${JSON.stringify(metadata)}`);
  },
});

/** Logger printing nothing, used when logging is deactivated */
const emptyLogger = {
  debug: (): null => null,
  info: (): null => null,
  warn: (): null => null,
  error: (): null => null,
  verbose: (): null => null,
};

/** Return a logger instance depending on the application context */
export const makeBrowserLogger = ({ isActive, level }: LogConfig): Logger => {
  const partialLogger = isActive ? makeFrontLogger(level) : emptyLogger;

  const logger: Logger = Object.assign(partialLogger, {
    generateMetadata,
    generateApiMetadata,
  });

  return logger;
};
