import {
  configuration,
  getConfigWithFilteredFeatureToggles,
  getMergedConfigWithAuthenticate,
  getMergedConfigWithInit,
} from '@canalplus/sdk-hodor';
import { ApiV2Authenticate } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { rewriteObjectUrls } from '../../helpers/proxyUrls/proxyUrls';
import { setApplicationConfigurationJson, setApplicationHodorSdkConfig } from '../../store/slices/application';
import {
  getFeatureToggleWhoIsWatching,
  platformSelector,
  shouldDisplayWhoIsWatchingSelector,
} from '../../store/slices/application-selectors';
import { performAuthenticate } from './Authenticate/performAuthenticate';
import { fetchInit } from './Init/fetchInit';
import { getWhiteListedFeatureToggles } from './Init/getWhiteListedFeatureToggles';
import { performInit } from './Init/performInit';
import { setInitTracking } from './Init/setInitTracking/setInitTracking';
import { setUserDetails } from './Init/setUserDetails/setUserDetails';
import { getHodorSdkConfig } from './getSdkConfig';
import { HodorCinematicParametersClient, HodorCinematicParametersUniversal } from './types';

/**
 * 1. Create an hodorSdkConfig
 * 2. Perform init and authenticate calls
 * 3. Update the request with the hodorSdkConfig computed with init and authenticate responses
 */
export async function performHodorCinematicUniversal({
  store: argStore,
  proxyAgent,
  request,
}: HodorCinematicParametersUniversal): Promise<ApiV2Authenticate | undefined> {
  const xorStoreRequest = argStore ? { store: argStore } : { request };
  const xorStateRequest = argStore ? { dispatch: argStore.dispatch, state: argStore.getState() } : { request };

  let storeState = request ? request.reduxStore.store.getState() : argStore.getState();

  const {
    api: {
      hodor: { platforms },
    },
  } = getPublicConfig();

  const platform = platformSelector(storeState);
  const { configVersion } = platforms[platform];

  const storeDispatch = request ? request.reduxStore.store.dispatch : argStore.dispatch;

  let hodorSdkConfig = getHodorSdkConfig({ proxyAgent, ...xorStateRequest });

  const configurationJsonData = await configuration(hodorSdkConfig, { version: configVersion });

  if (request) {
    request.configurationJson = JSON.stringify(configurationJsonData);
  }

  storeDispatch(setApplicationConfigurationJson(configurationJsonData));

  // Needs to updated store with configurationJson data inside
  storeState = request ? request.reduxStore.store.getState() : argStore.getState();

  let authenticateResponse: ApiV2Authenticate | undefined;

  hodorSdkConfig = await performInit({ config: hodorSdkConfig, ...xorStoreRequest });

  const isFeatWhoIsWatching = getFeatureToggleWhoIsWatching(storeState);

  // If shouldDisplayWhoIsWatching and isFeatWhoIsWatching, not call hodor authenticate.
  // It will be called in clientSide when the user select his profile (see useProfileSelector)
  const shouldDisplayWhoIsWatching = isFeatWhoIsWatching && shouldDisplayWhoIsWatchingSelector(storeState);

  if (!shouldDisplayWhoIsWatching) {
    const perfomAuthenticateResult = await performAuthenticate({
      configWithInit: hodorSdkConfig,
      ...xorStoreRequest,
    });

    authenticateResponse = perfomAuthenticateResult.authenticateResponse;
    hodorSdkConfig = perfomAuthenticateResult.hodorSdkConfig;
  }

  if (request) {
    request.hodorSdkConfig =
      !shouldDisplayWhoIsWatching && authenticateResponse
        ? getMergedConfigWithAuthenticate(hodorSdkConfig, authenticateResponse)
        : hodorSdkConfig;
  }

  return authenticateResponse;
}

/**
 * 1. Create an hodorSdkConfig
 * 2. Perform init call
 * 3. Dispatch the hodorSdkConfig computed with init response in redux store
 * 4. Set user details
 * 5. Set init tracking
 *
 * If the **WhoIsWatching** feature isn't enabled, the **hodorSdkConfig** will be filtered with the whiteListedFeatureToggles\
 * Otherwise, the hodorSdkConfig will be filtered later in the `useProfileSelector` hook
 */
export async function performHodorCinematicClient({ store }: HodorCinematicParametersClient): Promise<void> {
  const state = store.getState();
  let config = getHodorSdkConfig({ dispatch: store.dispatch, state });

  const init = rewriteObjectUrls(await fetchInit({ config, state }));
  config = getMergedConfigWithInit(config, init);

  const isWhoIsWatchingEnabled = getFeatureToggleWhoIsWatching(state) && shouldDisplayWhoIsWatchingSelector(state);

  if (!isWhoIsWatchingEnabled) {
    config = getConfigWithFilteredFeatureToggles({
      config,
      whiteListedFeatureToggles: getWhiteListedFeatureToggles(state),
    });
  }

  store.dispatch(setApplicationHodorSdkConfig(config));

  setUserDetails({ init, store });
  setInitTracking({ init, store });
}
