import { PlatformGroup } from '@canalplus/sdk-core';
import type { AuthenticateParameters } from '@canalplus/sdk-hodor';
import type { Request } from 'express';
import { XOR } from 'ts-essentials';
import { CookieKey } from '../../../constants/cookie';
import { platformGroupSelector } from '../../../store/slices/application-selectors';
import {
  abTestingPopulationSelector,
  authenticatedSelector,
  epgidOTTSelector,
  epgidSelector,
  hasUserDataCollectedSelector,
  isKidsProfileSelector,
} from '../../../store/slices/user-selectors';
import type { IState } from '../../../store/types/State-type';

const getClientParameters = (
  state: IState,
  isKidsProfile?: boolean,
  shouldCollectUserData?: boolean
): Omit<AuthenticateParameters, 'url'> => ({
  isKidsProfile: isKidsProfile !== undefined ? isKidsProfile : isKidsProfileSelector(state),
  shouldCollectUserData:
    shouldCollectUserData !== undefined ? shouldCollectUserData : hasUserDataCollectedSelector(state),
});

const getServerParameters = ({
  request,
}: Required<Pick<GetSdkAuthenticateParameters, 'request'>>): Omit<AuthenticateParameters, 'url'> => {
  const { cookies, userData } = request;
  const { [CookieKey.IsKidsProfile]: isKidsProfile } = cookies || {};

  return {
    isKidsProfile: isKidsProfile === 'true',
    shouldCollectUserData: userData.collectUserData,
  };
};

type GetSdkAuthenticateParameters = XOR<
  { request: Request },
  { store: Redux.CustomStore; isKidsProfile?: boolean; shouldCollectUserData?: boolean }
>;

/**
 * Retrieve some **Authenticate** parameters\
 * Can be used both on server and client side.
 *
 * Do not handle:
 * - `url`
 */
export const getSdkAuthenticateParameters = ({
  store,
  request,
  isKidsProfile,
  shouldCollectUserData,
}: GetSdkAuthenticateParameters): Omit<AuthenticateParameters, 'url'> => {
  const state = request ? request.reduxStore.store.getState() : store.getState();
  const platformGroup = platformGroupSelector(state);

  const configRenderModeSpecific = request
    ? getServerParameters({ request })
    : getClientParameters(state, isKidsProfile, shouldCollectUserData);

  return {
    ...configRenderModeSpecific,
    abTestingPopulation: abTestingPopulationSelector(state) || 0,
    isAuthenticated: authenticatedSelector(state),
    // Maybe use a helper `shouldUseEpgIdOTT(platformGroup)`
    pdsNormal: `[${platformGroup === PlatformGroup.Orange ? epgidSelector(state) : epgidOTTSelector(state)}]`,
  };
};
