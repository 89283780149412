import { getPublicConfig } from '../../../helpers/config/config-helper';
import {
  getFeatureToggleDetailLight,
  getFeatureToggleDetailV5Sport,
  getFeatureTogglePromotionBanner,
  getFeatureTogglePromotionCover,
} from '../../../store/slices/application-selectors';
import type { IState } from '../../../store/types/State-type';

/**
 * Return a featureToggles list that must be kept in the `HodorSdkConfig`\
 * Used to populate the `whiteListedFeatureToggles` parameter of `getConfigWithFilteredFeatureToggles`
 */
export const getWhiteListedFeatureToggles = (state: IState): string[] => {
  const featureToggles = getPublicConfig().api.hodor.initParameters.featureToggles?.split(',') || [];
  const toRemove = [
    !getFeatureToggleDetailLight(state) ? 'detailLight' : '',
    !getFeatureToggleDetailV5Sport(state) ? 'detailV5Sport' : '',
    !getFeatureTogglePromotionCover(state) ? 'promotionCover' : '',
    !getFeatureTogglePromotionBanner(state) ? 'promotionBanner' : '',
  ].filter(Boolean);

  return featureToggles.filter((featureToggle) => !toRemove.includes(featureToggle));
};
